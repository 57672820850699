import * as Types from '../../types/bff/gql-schema';

import { useQuery, useMutation, UseQueryOptions, UseMutationOptions } from '@tanstack/react-query';
import { gqlFetcher } from '@/utils/fetcher';

export const GetImageBannersDocument = `
    query getImageBanners($request: ImageBannersRequestInput!) {
  imageBanners(request: $request) {
    image360Url
    image640Url
    linkUrl
  }
}
    `;
export const useGetImageBannersQuery = <
      TData = Types.GetImageBannersQuery,
      TError = Types.GraphqlError
    >(
      variables: Types.GetImageBannersQueryVariables,
      options?: UseQueryOptions<Types.GetImageBannersQuery, TError, TData>
    ) =>
    useQuery<Types.GetImageBannersQuery, TError, TData>(
      ['getImageBanners', variables],
      gqlFetcher<Types.GetImageBannersQuery, Types.GetImageBannersQueryVariables>(GetImageBannersDocument, variables),
      options
    );

useGetImageBannersQuery.getKey = (variables: Types.GetImageBannersQueryVariables) => ['getImageBanners', variables];
;

useGetImageBannersQuery.fetcher = (variables: Types.GetImageBannersQueryVariables, options?: RequestInit['headers']) => gqlFetcher<Types.GetImageBannersQuery, Types.GetImageBannersQueryVariables>(GetImageBannersDocument, variables, options);
export const GetQnaRegistrationStatusDocument = `
    query GetQnaRegistrationStatus($request: QnaRegistrationStatusRequestInput!) {
  qnaRegistrationStatus(request: $request) {
    registeredAnswerCount
    registeredQuestionCount
  }
}
    `;
export const useGetQnaRegistrationStatusQuery = <
      TData = Types.GetQnaRegistrationStatusQuery,
      TError = Types.GraphqlError
    >(
      variables: Types.GetQnaRegistrationStatusQueryVariables,
      options?: UseQueryOptions<Types.GetQnaRegistrationStatusQuery, TError, TData>
    ) =>
    useQuery<Types.GetQnaRegistrationStatusQuery, TError, TData>(
      ['GetQnaRegistrationStatus', variables],
      gqlFetcher<Types.GetQnaRegistrationStatusQuery, Types.GetQnaRegistrationStatusQueryVariables>(GetQnaRegistrationStatusDocument, variables),
      options
    );

useGetQnaRegistrationStatusQuery.getKey = (variables: Types.GetQnaRegistrationStatusQueryVariables) => ['GetQnaRegistrationStatus', variables];
;

useGetQnaRegistrationStatusQuery.fetcher = (variables: Types.GetQnaRegistrationStatusQueryVariables, options?: RequestInit['headers']) => gqlFetcher<Types.GetQnaRegistrationStatusQuery, Types.GetQnaRegistrationStatusQueryVariables>(GetQnaRegistrationStatusDocument, variables, options);
export const GetQuestionDocument = `
    query GetQuestion($questionPid: ID!, $size: Int) {
  question(questionPid: $questionPid) {
    paymentInfo {
      discountAmount
      paidAmount
      paidAt
      payTryCount
      price
      usedPoint
    }
    paymentTotal {
      currentAmount
      payTrackingNumber
      reason
      status
      userPaymentId
      userPayment {
        cardName
        cardNumber
        id
      }
    }
    status
    accessibleType
    answeredStatus
    answererCount
    answererProfileImageUrls
    answers {
      answerer {
        affiliatedInstitutionName
        answererId
        answererType
        name
        profileImageUrl
      }
      content
      createdAt
      footer
      header
      recommendedMagazineGraph {
        description
        magazinePid
        title
        titleImageUrl
      }
    }
    attachments {
      blindType
      questionAttachmentId
    }
    category {
      name
      type
      questionCategoryId
    }
    content
    createdAt
    likeCount
    questionPid
    showUserPersonalInfo
    tags {
      name
      questionTagId
    }
    title
    user {
      name
      name
      birth
      gender
    }
    viewCount
  }
  nearSimpleQuestions(request: {questionPid: $questionPid, size: $size}) {
    questionPid
    title
  }
  similarSearchQuestionCardCursorPageRequest(request: {questionPid: $questionPid}) {
    elements {
      question {
        questionPid
        title
      }
    }
  }
}
    `;
export const useGetQuestionQuery = <
      TData = Types.GetQuestionQuery,
      TError = Types.GraphqlError
    >(
      variables: Types.GetQuestionQueryVariables,
      options?: UseQueryOptions<Types.GetQuestionQuery, TError, TData>
    ) =>
    useQuery<Types.GetQuestionQuery, TError, TData>(
      ['GetQuestion', variables],
      gqlFetcher<Types.GetQuestionQuery, Types.GetQuestionQueryVariables>(GetQuestionDocument, variables),
      options
    );

useGetQuestionQuery.getKey = (variables: Types.GetQuestionQueryVariables) => ['GetQuestion', variables];
;

useGetQuestionQuery.fetcher = (variables: Types.GetQuestionQueryVariables, options?: RequestInit['headers']) => gqlFetcher<Types.GetQuestionQuery, Types.GetQuestionQueryVariables>(GetQuestionDocument, variables, options);
export const GetBestQuestionCardsDocument = `
    query GetBestQuestionCards {
  bestQuestionCards {
    question {
      questionPid
      title
    }
  }
}
    `;
export const useGetBestQuestionCardsQuery = <
      TData = Types.GetBestQuestionCardsQuery,
      TError = Types.GraphqlError
    >(
      variables?: Types.GetBestQuestionCardsQueryVariables,
      options?: UseQueryOptions<Types.GetBestQuestionCardsQuery, TError, TData>
    ) =>
    useQuery<Types.GetBestQuestionCardsQuery, TError, TData>(
      variables === undefined ? ['GetBestQuestionCards'] : ['GetBestQuestionCards', variables],
      gqlFetcher<Types.GetBestQuestionCardsQuery, Types.GetBestQuestionCardsQueryVariables>(GetBestQuestionCardsDocument, variables),
      options
    );

useGetBestQuestionCardsQuery.getKey = (variables?: Types.GetBestQuestionCardsQueryVariables) => variables === undefined ? ['GetBestQuestionCards'] : ['GetBestQuestionCards', variables];
;

useGetBestQuestionCardsQuery.fetcher = (variables?: Types.GetBestQuestionCardsQueryVariables, options?: RequestInit['headers']) => gqlFetcher<Types.GetBestQuestionCardsQuery, Types.GetBestQuestionCardsQueryVariables>(GetBestQuestionCardsDocument, variables, options);
export const GetCurationListDocument = `
    query GetCurationList($request: MagazineCursorPageRequestInput!) {
  magazineCursorPage(request: $request) {
    elements {
      createdAt
      description
      magazinePid
      title
      titleImageUrl
    }
    pivot
  }
}
    `;
export const useGetCurationListQuery = <
      TData = Types.GetCurationListQuery,
      TError = Types.GraphqlError
    >(
      variables: Types.GetCurationListQueryVariables,
      options?: UseQueryOptions<Types.GetCurationListQuery, TError, TData>
    ) =>
    useQuery<Types.GetCurationListQuery, TError, TData>(
      ['GetCurationList', variables],
      gqlFetcher<Types.GetCurationListQuery, Types.GetCurationListQueryVariables>(GetCurationListDocument, variables),
      options
    );

useGetCurationListQuery.getKey = (variables: Types.GetCurationListQueryVariables) => ['GetCurationList', variables];
;

useGetCurationListQuery.fetcher = (variables: Types.GetCurationListQueryVariables, options?: RequestInit['headers']) => gqlFetcher<Types.GetCurationListQuery, Types.GetCurationListQueryVariables>(GetCurationListDocument, variables, options);
export const GetBestMagazineDocument = `
    query GetBestMagazine {
  bestMagazineV2 {
    createdAt
    description
    likeCount
    magazinePid
    title
    titleImageUrl
  }
}
    `;
export const useGetBestMagazineQuery = <
      TData = Types.GetBestMagazineQuery,
      TError = Types.GraphqlError
    >(
      variables?: Types.GetBestMagazineQueryVariables,
      options?: UseQueryOptions<Types.GetBestMagazineQuery, TError, TData>
    ) =>
    useQuery<Types.GetBestMagazineQuery, TError, TData>(
      variables === undefined ? ['GetBestMagazine'] : ['GetBestMagazine', variables],
      gqlFetcher<Types.GetBestMagazineQuery, Types.GetBestMagazineQueryVariables>(GetBestMagazineDocument, variables),
      options
    );

useGetBestMagazineQuery.getKey = (variables?: Types.GetBestMagazineQueryVariables) => variables === undefined ? ['GetBestMagazine'] : ['GetBestMagazine', variables];
;

useGetBestMagazineQuery.fetcher = (variables?: Types.GetBestMagazineQueryVariables, options?: RequestInit['headers']) => gqlFetcher<Types.GetBestMagazineQuery, Types.GetBestMagazineQueryVariables>(GetBestMagazineDocument, variables, options);
export const GetMagazineListDocument = `
    query GetMagazineList($request: MagazineCursorPageRequestInput!) {
  magazineCursorPage(request: $request) {
    elements {
      createdAt
      description
      magazinePid
      title
      titleImageUrl
    }
    pivot
  }
}
    `;
export const useGetMagazineListQuery = <
      TData = Types.GetMagazineListQuery,
      TError = Types.GraphqlError
    >(
      variables: Types.GetMagazineListQueryVariables,
      options?: UseQueryOptions<Types.GetMagazineListQuery, TError, TData>
    ) =>
    useQuery<Types.GetMagazineListQuery, TError, TData>(
      ['GetMagazineList', variables],
      gqlFetcher<Types.GetMagazineListQuery, Types.GetMagazineListQueryVariables>(GetMagazineListDocument, variables),
      options
    );

useGetMagazineListQuery.getKey = (variables: Types.GetMagazineListQueryVariables) => ['GetMagazineList', variables];
;

useGetMagazineListQuery.fetcher = (variables: Types.GetMagazineListQueryVariables, options?: RequestInit['headers']) => gqlFetcher<Types.GetMagazineListQuery, Types.GetMagazineListQueryVariables>(GetMagazineListDocument, variables, options);
export const GetMagazineCountDocument = `
    query GetMagazineCount($magazineCountRequestInput: MagazineCountRequestInput!) {
  magazineCount(request: $magazineCountRequestInput) {
    magazineCount
  }
}
    `;
export const useGetMagazineCountQuery = <
      TData = Types.GetMagazineCountQuery,
      TError = Types.GraphqlError
    >(
      variables: Types.GetMagazineCountQueryVariables,
      options?: UseQueryOptions<Types.GetMagazineCountQuery, TError, TData>
    ) =>
    useQuery<Types.GetMagazineCountQuery, TError, TData>(
      ['GetMagazineCount', variables],
      gqlFetcher<Types.GetMagazineCountQuery, Types.GetMagazineCountQueryVariables>(GetMagazineCountDocument, variables),
      options
    );

useGetMagazineCountQuery.getKey = (variables: Types.GetMagazineCountQueryVariables) => ['GetMagazineCount', variables];
;

useGetMagazineCountQuery.fetcher = (variables: Types.GetMagazineCountQueryVariables, options?: RequestInit['headers']) => gqlFetcher<Types.GetMagazineCountQuery, Types.GetMagazineCountQueryVariables>(GetMagazineCountDocument, variables, options);
export const GetQuestionCategoryGroupsDocument = `
    query getQuestionCategoryGroups {
  questionCategoryGroups {
    key
    name
    questionCategories {
      description
      name
      questionCategoryId
      tags {
        name
        questionTagId
      }
    }
  }
}
    `;
export const useGetQuestionCategoryGroupsQuery = <
      TData = Types.GetQuestionCategoryGroupsQuery,
      TError = Types.GraphqlError
    >(
      variables?: Types.GetQuestionCategoryGroupsQueryVariables,
      options?: UseQueryOptions<Types.GetQuestionCategoryGroupsQuery, TError, TData>
    ) =>
    useQuery<Types.GetQuestionCategoryGroupsQuery, TError, TData>(
      variables === undefined ? ['getQuestionCategoryGroups'] : ['getQuestionCategoryGroups', variables],
      gqlFetcher<Types.GetQuestionCategoryGroupsQuery, Types.GetQuestionCategoryGroupsQueryVariables>(GetQuestionCategoryGroupsDocument, variables),
      options
    );

useGetQuestionCategoryGroupsQuery.getKey = (variables?: Types.GetQuestionCategoryGroupsQueryVariables) => variables === undefined ? ['getQuestionCategoryGroups'] : ['getQuestionCategoryGroups', variables];
;

useGetQuestionCategoryGroupsQuery.fetcher = (variables?: Types.GetQuestionCategoryGroupsQueryVariables, options?: RequestInit['headers']) => gqlFetcher<Types.GetQuestionCategoryGroupsQuery, Types.GetQuestionCategoryGroupsQueryVariables>(GetQuestionCategoryGroupsDocument, variables, options);
export const NewestQuestionCardCursorPageDocument = `
    query newestQuestionCardCursorPage($request: NewestQuestionCardCursorPageRequestInput!) {
  newestQuestionCardCursorPage(request: $request) {
    pivot
    elements {
      answeredStatus
      answererCount
      answererProfileImageUrls
      question {
        status
        accessibleType
        attachments {
          blindType
          questionAttachmentId
        }
        category {
          name
          questionCategoryId
        }
        content
        createdAt
        likeCount
        questionPid
        tags {
          name
        }
        title
        user {
          name
        }
        viewCount
      }
    }
  }
}
    `;
export const useNewestQuestionCardCursorPageQuery = <
      TData = Types.NewestQuestionCardCursorPageQuery,
      TError = Types.GraphqlError
    >(
      variables: Types.NewestQuestionCardCursorPageQueryVariables,
      options?: UseQueryOptions<Types.NewestQuestionCardCursorPageQuery, TError, TData>
    ) =>
    useQuery<Types.NewestQuestionCardCursorPageQuery, TError, TData>(
      ['newestQuestionCardCursorPage', variables],
      gqlFetcher<Types.NewestQuestionCardCursorPageQuery, Types.NewestQuestionCardCursorPageQueryVariables>(NewestQuestionCardCursorPageDocument, variables),
      options
    );

useNewestQuestionCardCursorPageQuery.getKey = (variables: Types.NewestQuestionCardCursorPageQueryVariables) => ['newestQuestionCardCursorPage', variables];
;

useNewestQuestionCardCursorPageQuery.fetcher = (variables: Types.NewestQuestionCardCursorPageQueryVariables, options?: RequestInit['headers']) => gqlFetcher<Types.NewestQuestionCardCursorPageQuery, Types.NewestQuestionCardCursorPageQueryVariables>(NewestQuestionCardCursorPageDocument, variables, options);
export const MagazineDocument = `
    query magazine($magazinePid: ID!) {
  magazineV2(magazinePid: $magazinePid) {
    author {
      affiliatedInstitutionName
      externalLinkUrl
      name
      profileImageUrl
      professionalFields {
        name
      }
    }
    isLikedByMe
    content
    createdAt
    description
    likeCount
    magazinePid
    questionCategoryId
    title
    titleImageUrl
    footnote
  }
}
    `;
export const useMagazineQuery = <
      TData = Types.MagazineQuery,
      TError = Types.GraphqlError
    >(
      variables: Types.MagazineQueryVariables,
      options?: UseQueryOptions<Types.MagazineQuery, TError, TData>
    ) =>
    useQuery<Types.MagazineQuery, TError, TData>(
      ['magazine', variables],
      gqlFetcher<Types.MagazineQuery, Types.MagazineQueryVariables>(MagazineDocument, variables),
      options
    );

useMagazineQuery.getKey = (variables: Types.MagazineQueryVariables) => ['magazine', variables];
;

useMagazineQuery.fetcher = (variables: Types.MagazineQueryVariables, options?: RequestInit['headers']) => gqlFetcher<Types.MagazineQuery, Types.MagazineQueryVariables>(MagazineDocument, variables, options);
export const GetQuestionAttachmentPreSignedUrlDocument = `
    query GetQuestionAttachmentPreSignedUrl($questionAttachmentId: ID!) {
  attachmentDownloadPreSignedUrl(questionAttachmentId: $questionAttachmentId)
}
    `;
export const useGetQuestionAttachmentPreSignedUrlQuery = <
      TData = Types.GetQuestionAttachmentPreSignedUrlQuery,
      TError = Types.GraphqlError
    >(
      variables: Types.GetQuestionAttachmentPreSignedUrlQueryVariables,
      options?: UseQueryOptions<Types.GetQuestionAttachmentPreSignedUrlQuery, TError, TData>
    ) =>
    useQuery<Types.GetQuestionAttachmentPreSignedUrlQuery, TError, TData>(
      ['GetQuestionAttachmentPreSignedUrl', variables],
      gqlFetcher<Types.GetQuestionAttachmentPreSignedUrlQuery, Types.GetQuestionAttachmentPreSignedUrlQueryVariables>(GetQuestionAttachmentPreSignedUrlDocument, variables),
      options
    );

useGetQuestionAttachmentPreSignedUrlQuery.getKey = (variables: Types.GetQuestionAttachmentPreSignedUrlQueryVariables) => ['GetQuestionAttachmentPreSignedUrl', variables];
;

useGetQuestionAttachmentPreSignedUrlQuery.fetcher = (variables: Types.GetQuestionAttachmentPreSignedUrlQueryVariables, options?: RequestInit['headers']) => gqlFetcher<Types.GetQuestionAttachmentPreSignedUrlQuery, Types.GetQuestionAttachmentPreSignedUrlQueryVariables>(GetQuestionAttachmentPreSignedUrlDocument, variables, options);
export const GetAttachmentThumbnailDownloadPreSignedUrlDocument = `
    query GetAttachmentThumbnailDownloadPreSignedUrl($questionAttachmentId: ID!) {
  attachmentThumbnailDownloadPreSignedUrl(
    questionAttachmentId: $questionAttachmentId
  )
}
    `;
export const useGetAttachmentThumbnailDownloadPreSignedUrlQuery = <
      TData = Types.GetAttachmentThumbnailDownloadPreSignedUrlQuery,
      TError = Types.GraphqlError
    >(
      variables: Types.GetAttachmentThumbnailDownloadPreSignedUrlQueryVariables,
      options?: UseQueryOptions<Types.GetAttachmentThumbnailDownloadPreSignedUrlQuery, TError, TData>
    ) =>
    useQuery<Types.GetAttachmentThumbnailDownloadPreSignedUrlQuery, TError, TData>(
      ['GetAttachmentThumbnailDownloadPreSignedUrl', variables],
      gqlFetcher<Types.GetAttachmentThumbnailDownloadPreSignedUrlQuery, Types.GetAttachmentThumbnailDownloadPreSignedUrlQueryVariables>(GetAttachmentThumbnailDownloadPreSignedUrlDocument, variables),
      options
    );

useGetAttachmentThumbnailDownloadPreSignedUrlQuery.getKey = (variables: Types.GetAttachmentThumbnailDownloadPreSignedUrlQueryVariables) => ['GetAttachmentThumbnailDownloadPreSignedUrl', variables];
;

useGetAttachmentThumbnailDownloadPreSignedUrlQuery.fetcher = (variables: Types.GetAttachmentThumbnailDownloadPreSignedUrlQueryVariables, options?: RequestInit['headers']) => gqlFetcher<Types.GetAttachmentThumbnailDownloadPreSignedUrlQuery, Types.GetAttachmentThumbnailDownloadPreSignedUrlQueryVariables>(GetAttachmentThumbnailDownloadPreSignedUrlDocument, variables, options);
export const AddQuestionDocument = `
    mutation AddQuestion($request: QuestionAddRequestInput!) {
  addQuestion(request: $request)
}
    `;
export const useAddQuestionMutation = <
      TError = Types.GraphqlError,
      TContext = unknown
    >(options?: UseMutationOptions<Types.AddQuestionMutation, TError, Types.AddQuestionMutationVariables, TContext>) =>
    useMutation<Types.AddQuestionMutation, TError, Types.AddQuestionMutationVariables, TContext>(
      ['AddQuestion'],
      (variables?: Types.AddQuestionMutationVariables) => gqlFetcher<Types.AddQuestionMutation, Types.AddQuestionMutationVariables>(AddQuestionDocument, variables)(),
      options
    );
useAddQuestionMutation.fetcher = (variables: Types.AddQuestionMutationVariables, options?: RequestInit['headers']) => gqlFetcher<Types.AddQuestionMutation, Types.AddQuestionMutationVariables>(AddQuestionDocument, variables, options);
export const AttachmentUploadPreSignedUrlDocument = `
    query AttachmentUploadPreSignedUrl($fileExtension: String!) {
  attachmentUploadPreSignedUrl(fileExtension: $fileExtension) {
    originalPath
    originalPreSignedUrl
    thumbnailPath
    thumbnailPreSignedUrl
  }
}
    `;
export const useAttachmentUploadPreSignedUrlQuery = <
      TData = Types.AttachmentUploadPreSignedUrlQuery,
      TError = Types.GraphqlError
    >(
      variables: Types.AttachmentUploadPreSignedUrlQueryVariables,
      options?: UseQueryOptions<Types.AttachmentUploadPreSignedUrlQuery, TError, TData>
    ) =>
    useQuery<Types.AttachmentUploadPreSignedUrlQuery, TError, TData>(
      ['AttachmentUploadPreSignedUrl', variables],
      gqlFetcher<Types.AttachmentUploadPreSignedUrlQuery, Types.AttachmentUploadPreSignedUrlQueryVariables>(AttachmentUploadPreSignedUrlDocument, variables),
      options
    );

useAttachmentUploadPreSignedUrlQuery.getKey = (variables: Types.AttachmentUploadPreSignedUrlQueryVariables) => ['AttachmentUploadPreSignedUrl', variables];
;

useAttachmentUploadPreSignedUrlQuery.fetcher = (variables: Types.AttachmentUploadPreSignedUrlQueryVariables, options?: RequestInit['headers']) => gqlFetcher<Types.AttachmentUploadPreSignedUrlQuery, Types.AttachmentUploadPreSignedUrlQueryVariables>(AttachmentUploadPreSignedUrlDocument, variables, options);
export const GetMyQuestionCardCursorPageDocument = `
    query getMyQuestionCardCursorPage($request: MyQuestionCardCursorPageRequestInput!) {
  myQuestionCardCursorPage(request: $request) {
    elements {
      paymentTotal {
        currentAmount
        payTrackingNumber
        reason
        status
        userPaymentId
        userPayment {
          cardName
          cardNumber
          id
        }
      }
      answeredStatus
      answererCount
      answererProfileImageUrls
      question {
        status
        accessibleType
        attachments {
          blindType
          questionAttachmentId
        }
        category {
          name
        }
        content
        createdAt
        isBlockedByMe
        isLikedByMe
        isMine
        likeCount
        questionPid
        tags {
          name
        }
        title
        user {
          name
          userId
        }
      }
    }
    pivot
  }
}
    `;
export const useGetMyQuestionCardCursorPageQuery = <
      TData = Types.GetMyQuestionCardCursorPageQuery,
      TError = Types.GraphqlError
    >(
      variables: Types.GetMyQuestionCardCursorPageQueryVariables,
      options?: UseQueryOptions<Types.GetMyQuestionCardCursorPageQuery, TError, TData>
    ) =>
    useQuery<Types.GetMyQuestionCardCursorPageQuery, TError, TData>(
      ['getMyQuestionCardCursorPage', variables],
      gqlFetcher<Types.GetMyQuestionCardCursorPageQuery, Types.GetMyQuestionCardCursorPageQueryVariables>(GetMyQuestionCardCursorPageDocument, variables),
      options
    );

useGetMyQuestionCardCursorPageQuery.getKey = (variables: Types.GetMyQuestionCardCursorPageQueryVariables) => ['getMyQuestionCardCursorPage', variables];
;

useGetMyQuestionCardCursorPageQuery.fetcher = (variables: Types.GetMyQuestionCardCursorPageQueryVariables, options?: RequestInit['headers']) => gqlFetcher<Types.GetMyQuestionCardCursorPageQuery, Types.GetMyQuestionCardCursorPageQueryVariables>(GetMyQuestionCardCursorPageDocument, variables, options);
export const NearSimpleQuestionsDocument = `
    query nearSimpleQuestions($request: NearSimpleQuestionsRequestInput!) {
  nearSimpleQuestions(request: $request) {
    questionPid
    title
  }
}
    `;
export const useNearSimpleQuestionsQuery = <
      TData = Types.NearSimpleQuestionsQuery,
      TError = Types.GraphqlError
    >(
      variables: Types.NearSimpleQuestionsQueryVariables,
      options?: UseQueryOptions<Types.NearSimpleQuestionsQuery, TError, TData>
    ) =>
    useQuery<Types.NearSimpleQuestionsQuery, TError, TData>(
      ['nearSimpleQuestions', variables],
      gqlFetcher<Types.NearSimpleQuestionsQuery, Types.NearSimpleQuestionsQueryVariables>(NearSimpleQuestionsDocument, variables),
      options
    );

useNearSimpleQuestionsQuery.getKey = (variables: Types.NearSimpleQuestionsQueryVariables) => ['nearSimpleQuestions', variables];
;

useNearSimpleQuestionsQuery.fetcher = (variables: Types.NearSimpleQuestionsQueryVariables, options?: RequestInit['headers']) => gqlFetcher<Types.NearSimpleQuestionsQuery, Types.NearSimpleQuestionsQueryVariables>(NearSimpleQuestionsDocument, variables, options);
export const QuestionCategoryDocument = `
    query questionCategory($request: QuestionCategoryRequestInput!) {
  questionCategory(request: $request) {
    name
    type
  }
}
    `;
export const useQuestionCategoryQuery = <
      TData = Types.QuestionCategoryQuery,
      TError = Types.GraphqlError
    >(
      variables: Types.QuestionCategoryQueryVariables,
      options?: UseQueryOptions<Types.QuestionCategoryQuery, TError, TData>
    ) =>
    useQuery<Types.QuestionCategoryQuery, TError, TData>(
      ['questionCategory', variables],
      gqlFetcher<Types.QuestionCategoryQuery, Types.QuestionCategoryQueryVariables>(QuestionCategoryDocument, variables),
      options
    );

useQuestionCategoryQuery.getKey = (variables: Types.QuestionCategoryQueryVariables) => ['questionCategory', variables];
;

useQuestionCategoryQuery.fetcher = (variables: Types.QuestionCategoryQueryVariables, options?: RequestInit['headers']) => gqlFetcher<Types.QuestionCategoryQuery, Types.QuestionCategoryQueryVariables>(QuestionCategoryDocument, variables, options);
export const GetBestOfQuestionCategoryDocument = `
    query GetBestOfQuestionCategory($request: CategoryBestSimpleQuestionsRequestInput!) {
  categoryBestSimpleQuestions(request: $request) {
    questionPid
    title
  }
}
    `;
export const useGetBestOfQuestionCategoryQuery = <
      TData = Types.GetBestOfQuestionCategoryQuery,
      TError = Types.GraphqlError
    >(
      variables: Types.GetBestOfQuestionCategoryQueryVariables,
      options?: UseQueryOptions<Types.GetBestOfQuestionCategoryQuery, TError, TData>
    ) =>
    useQuery<Types.GetBestOfQuestionCategoryQuery, TError, TData>(
      ['GetBestOfQuestionCategory', variables],
      gqlFetcher<Types.GetBestOfQuestionCategoryQuery, Types.GetBestOfQuestionCategoryQueryVariables>(GetBestOfQuestionCategoryDocument, variables),
      options
    );

useGetBestOfQuestionCategoryQuery.getKey = (variables: Types.GetBestOfQuestionCategoryQueryVariables) => ['GetBestOfQuestionCategory', variables];
;

useGetBestOfQuestionCategoryQuery.fetcher = (variables: Types.GetBestOfQuestionCategoryQueryVariables, options?: RequestInit['headers']) => gqlFetcher<Types.GetBestOfQuestionCategoryQuery, Types.GetBestOfQuestionCategoryQueryVariables>(GetBestOfQuestionCategoryDocument, variables, options);
export const GetquestionTitleGeneratedDocument = `
    query GetquestionTitleGenerated($questionPid: ID!) {
  questionTitleGenerated(questionPid: $questionPid)
}
    `;
export const useGetquestionTitleGeneratedQuery = <
      TData = Types.GetquestionTitleGeneratedQuery,
      TError = Types.GraphqlError
    >(
      variables: Types.GetquestionTitleGeneratedQueryVariables,
      options?: UseQueryOptions<Types.GetquestionTitleGeneratedQuery, TError, TData>
    ) =>
    useQuery<Types.GetquestionTitleGeneratedQuery, TError, TData>(
      ['GetquestionTitleGenerated', variables],
      gqlFetcher<Types.GetquestionTitleGeneratedQuery, Types.GetquestionTitleGeneratedQueryVariables>(GetquestionTitleGeneratedDocument, variables),
      options
    );

useGetquestionTitleGeneratedQuery.getKey = (variables: Types.GetquestionTitleGeneratedQueryVariables) => ['GetquestionTitleGenerated', variables];
;

useGetquestionTitleGeneratedQuery.fetcher = (variables: Types.GetquestionTitleGeneratedQueryVariables, options?: RequestInit['headers']) => gqlFetcher<Types.GetquestionTitleGeneratedQuery, Types.GetquestionTitleGeneratedQueryVariables>(GetquestionTitleGeneratedDocument, variables, options);
export const GetAgreementsForQuestionCategoryDocument = `
    query GetAgreementsForQuestionCategory {
  getAgreementsByCategory(category: QNA_AGREEMENT) {
    id
    required
    title
    url
    type
  }
}
    `;
export const useGetAgreementsForQuestionCategoryQuery = <
      TData = Types.GetAgreementsForQuestionCategoryQuery,
      TError = Types.GraphqlError
    >(
      variables?: Types.GetAgreementsForQuestionCategoryQueryVariables,
      options?: UseQueryOptions<Types.GetAgreementsForQuestionCategoryQuery, TError, TData>
    ) =>
    useQuery<Types.GetAgreementsForQuestionCategoryQuery, TError, TData>(
      variables === undefined ? ['GetAgreementsForQuestionCategory'] : ['GetAgreementsForQuestionCategory', variables],
      gqlFetcher<Types.GetAgreementsForQuestionCategoryQuery, Types.GetAgreementsForQuestionCategoryQueryVariables>(GetAgreementsForQuestionCategoryDocument, variables),
      options
    );

useGetAgreementsForQuestionCategoryQuery.getKey = (variables?: Types.GetAgreementsForQuestionCategoryQueryVariables) => variables === undefined ? ['GetAgreementsForQuestionCategory'] : ['GetAgreementsForQuestionCategory', variables];
;

useGetAgreementsForQuestionCategoryQuery.fetcher = (variables?: Types.GetAgreementsForQuestionCategoryQueryVariables, options?: RequestInit['headers']) => gqlFetcher<Types.GetAgreementsForQuestionCategoryQuery, Types.GetAgreementsForQuestionCategoryQueryVariables>(GetAgreementsForQuestionCategoryDocument, variables, options);
export const GetCheckApprovedByCategoryDocument = `
    query GetCheckApprovedByCategory($category: AgreementCategory!) {
  checkApproved(category: $category)
}
    `;
export const useGetCheckApprovedByCategoryQuery = <
      TData = Types.GetCheckApprovedByCategoryQuery,
      TError = Types.GraphqlError
    >(
      variables: Types.GetCheckApprovedByCategoryQueryVariables,
      options?: UseQueryOptions<Types.GetCheckApprovedByCategoryQuery, TError, TData>
    ) =>
    useQuery<Types.GetCheckApprovedByCategoryQuery, TError, TData>(
      ['GetCheckApprovedByCategory', variables],
      gqlFetcher<Types.GetCheckApprovedByCategoryQuery, Types.GetCheckApprovedByCategoryQueryVariables>(GetCheckApprovedByCategoryDocument, variables),
      options
    );

useGetCheckApprovedByCategoryQuery.getKey = (variables: Types.GetCheckApprovedByCategoryQueryVariables) => ['GetCheckApprovedByCategory', variables];
;

useGetCheckApprovedByCategoryQuery.fetcher = (variables: Types.GetCheckApprovedByCategoryQueryVariables, options?: RequestInit['headers']) => gqlFetcher<Types.GetCheckApprovedByCategoryQuery, Types.GetCheckApprovedByCategoryQueryVariables>(GetCheckApprovedByCategoryDocument, variables, options);
export const GetCheckApprovedByTypeDocument = `
    query GetCheckApprovedByType($request: AgreementsApprovedCheckApiRequestInput!) {
  checkApprovedByType(request: $request) {
    result
  }
}
    `;
export const useGetCheckApprovedByTypeQuery = <
      TData = Types.GetCheckApprovedByTypeQuery,
      TError = Types.GraphqlError
    >(
      variables: Types.GetCheckApprovedByTypeQueryVariables,
      options?: UseQueryOptions<Types.GetCheckApprovedByTypeQuery, TError, TData>
    ) =>
    useQuery<Types.GetCheckApprovedByTypeQuery, TError, TData>(
      ['GetCheckApprovedByType', variables],
      gqlFetcher<Types.GetCheckApprovedByTypeQuery, Types.GetCheckApprovedByTypeQueryVariables>(GetCheckApprovedByTypeDocument, variables),
      options
    );

useGetCheckApprovedByTypeQuery.getKey = (variables: Types.GetCheckApprovedByTypeQueryVariables) => ['GetCheckApprovedByType', variables];
;

useGetCheckApprovedByTypeQuery.fetcher = (variables: Types.GetCheckApprovedByTypeQueryVariables, options?: RequestInit['headers']) => gqlFetcher<Types.GetCheckApprovedByTypeQuery, Types.GetCheckApprovedByTypeQueryVariables>(GetCheckApprovedByTypeDocument, variables, options);
export const QuestionOrderSheetByUserDocument = `
    query QuestionOrderSheetByUser($request: QuestionOrderSheetRequestInput!) {
  questionOrderSheetByUser(request: $request) {
    expectedAmountToPay
    questionPid
    totalDiscountAmount
    totalPrice
  }
}
    `;
export const useQuestionOrderSheetByUserQuery = <
      TData = Types.QuestionOrderSheetByUserQuery,
      TError = Types.GraphqlError
    >(
      variables: Types.QuestionOrderSheetByUserQueryVariables,
      options?: UseQueryOptions<Types.QuestionOrderSheetByUserQuery, TError, TData>
    ) =>
    useQuery<Types.QuestionOrderSheetByUserQuery, TError, TData>(
      ['QuestionOrderSheetByUser', variables],
      gqlFetcher<Types.QuestionOrderSheetByUserQuery, Types.QuestionOrderSheetByUserQueryVariables>(QuestionOrderSheetByUserDocument, variables),
      options
    );

useQuestionOrderSheetByUserQuery.getKey = (variables: Types.QuestionOrderSheetByUserQueryVariables) => ['QuestionOrderSheetByUser', variables];
;

useQuestionOrderSheetByUserQuery.fetcher = (variables: Types.QuestionOrderSheetByUserQueryVariables, options?: RequestInit['headers']) => gqlFetcher<Types.QuestionOrderSheetByUserQuery, Types.QuestionOrderSheetByUserQueryVariables>(QuestionOrderSheetByUserDocument, variables, options);
export const PayQuestionDocument = `
    mutation PayQuestion($request: PayQuestionRequestInput!) {
  payQuestion(request: $request)
}
    `;
export const usePayQuestionMutation = <
      TError = Types.GraphqlError,
      TContext = unknown
    >(options?: UseMutationOptions<Types.PayQuestionMutation, TError, Types.PayQuestionMutationVariables, TContext>) =>
    useMutation<Types.PayQuestionMutation, TError, Types.PayQuestionMutationVariables, TContext>(
      ['PayQuestion'],
      (variables?: Types.PayQuestionMutationVariables) => gqlFetcher<Types.PayQuestionMutation, Types.PayQuestionMutationVariables>(PayQuestionDocument, variables)(),
      options
    );
usePayQuestionMutation.fetcher = (variables: Types.PayQuestionMutationVariables, options?: RequestInit['headers']) => gqlFetcher<Types.PayQuestionMutation, Types.PayQuestionMutationVariables>(PayQuestionDocument, variables, options);
export const RepayQuestionDocument = `
    mutation RepayQuestion($request: RepayQuestionRequestInput!) {
  repayQuestion(request: $request)
}
    `;
export const useRepayQuestionMutation = <
      TError = Types.GraphqlError,
      TContext = unknown
    >(options?: UseMutationOptions<Types.RepayQuestionMutation, TError, Types.RepayQuestionMutationVariables, TContext>) =>
    useMutation<Types.RepayQuestionMutation, TError, Types.RepayQuestionMutationVariables, TContext>(
      ['RepayQuestion'],
      (variables?: Types.RepayQuestionMutationVariables) => gqlFetcher<Types.RepayQuestionMutation, Types.RepayQuestionMutationVariables>(RepayQuestionDocument, variables)(),
      options
    );
useRepayQuestionMutation.fetcher = (variables: Types.RepayQuestionMutationVariables, options?: RequestInit['headers']) => gqlFetcher<Types.RepayQuestionMutation, Types.RepayQuestionMutationVariables>(RepayQuestionDocument, variables, options);
export const BeDissatisfiedWithAiAnswerDocument = `
    mutation BeDissatisfiedWithAiAnswer($request: BeDissatisfiedWithAiAnswerRequestInput!) {
  beDissatisfiedWithAiAnswer(request: $request)
}
    `;
export const useBeDissatisfiedWithAiAnswerMutation = <
      TError = Types.GraphqlError,
      TContext = unknown
    >(options?: UseMutationOptions<Types.BeDissatisfiedWithAiAnswerMutation, TError, Types.BeDissatisfiedWithAiAnswerMutationVariables, TContext>) =>
    useMutation<Types.BeDissatisfiedWithAiAnswerMutation, TError, Types.BeDissatisfiedWithAiAnswerMutationVariables, TContext>(
      ['BeDissatisfiedWithAiAnswer'],
      (variables?: Types.BeDissatisfiedWithAiAnswerMutationVariables) => gqlFetcher<Types.BeDissatisfiedWithAiAnswerMutation, Types.BeDissatisfiedWithAiAnswerMutationVariables>(BeDissatisfiedWithAiAnswerDocument, variables)(),
      options
    );
useBeDissatisfiedWithAiAnswerMutation.fetcher = (variables: Types.BeDissatisfiedWithAiAnswerMutationVariables, options?: RequestInit['headers']) => gqlFetcher<Types.BeDissatisfiedWithAiAnswerMutation, Types.BeDissatisfiedWithAiAnswerMutationVariables>(BeDissatisfiedWithAiAnswerDocument, variables, options);
export const BeSatisfiedWithAiAnswerDocument = `
    mutation BeSatisfiedWithAiAnswer($request: BeSatisfiedWithAiAnswerRequestInput!) {
  beSatisfiedWithAiAnswer(request: $request)
}
    `;
export const useBeSatisfiedWithAiAnswerMutation = <
      TError = Types.GraphqlError,
      TContext = unknown
    >(options?: UseMutationOptions<Types.BeSatisfiedWithAiAnswerMutation, TError, Types.BeSatisfiedWithAiAnswerMutationVariables, TContext>) =>
    useMutation<Types.BeSatisfiedWithAiAnswerMutation, TError, Types.BeSatisfiedWithAiAnswerMutationVariables, TContext>(
      ['BeSatisfiedWithAiAnswer'],
      (variables?: Types.BeSatisfiedWithAiAnswerMutationVariables) => gqlFetcher<Types.BeSatisfiedWithAiAnswerMutation, Types.BeSatisfiedWithAiAnswerMutationVariables>(BeSatisfiedWithAiAnswerDocument, variables)(),
      options
    );
useBeSatisfiedWithAiAnswerMutation.fetcher = (variables: Types.BeSatisfiedWithAiAnswerMutationVariables, options?: RequestInit['headers']) => gqlFetcher<Types.BeSatisfiedWithAiAnswerMutation, Types.BeSatisfiedWithAiAnswerMutationVariables>(BeSatisfiedWithAiAnswerDocument, variables, options);
export const CreateQnaAnswerReviewDocument = `
    mutation CreateQnaAnswerReview($request: QnaAnswerReviewCreateRequestInput!) {
  createQnaAnswerReview(request: $request)
}
    `;
export const useCreateQnaAnswerReviewMutation = <
      TError = Types.GraphqlError,
      TContext = unknown
    >(options?: UseMutationOptions<Types.CreateQnaAnswerReviewMutation, TError, Types.CreateQnaAnswerReviewMutationVariables, TContext>) =>
    useMutation<Types.CreateQnaAnswerReviewMutation, TError, Types.CreateQnaAnswerReviewMutationVariables, TContext>(
      ['CreateQnaAnswerReview'],
      (variables?: Types.CreateQnaAnswerReviewMutationVariables) => gqlFetcher<Types.CreateQnaAnswerReviewMutation, Types.CreateQnaAnswerReviewMutationVariables>(CreateQnaAnswerReviewDocument, variables)(),
      options
    );
useCreateQnaAnswerReviewMutation.fetcher = (variables: Types.CreateQnaAnswerReviewMutationVariables, options?: RequestInit['headers']) => gqlFetcher<Types.CreateQnaAnswerReviewMutation, Types.CreateQnaAnswerReviewMutationVariables>(CreateQnaAnswerReviewDocument, variables, options);
export const QnaAnswerReviewTagsDocument = `
    query QnaAnswerReviewTags {
  qnaAnswerReviewTags {
    exposureType
    id
    text
  }
}
    `;
export const useQnaAnswerReviewTagsQuery = <
      TData = Types.QnaAnswerReviewTagsQuery,
      TError = Types.GraphqlError
    >(
      variables?: Types.QnaAnswerReviewTagsQueryVariables,
      options?: UseQueryOptions<Types.QnaAnswerReviewTagsQuery, TError, TData>
    ) =>
    useQuery<Types.QnaAnswerReviewTagsQuery, TError, TData>(
      variables === undefined ? ['QnaAnswerReviewTags'] : ['QnaAnswerReviewTags', variables],
      gqlFetcher<Types.QnaAnswerReviewTagsQuery, Types.QnaAnswerReviewTagsQueryVariables>(QnaAnswerReviewTagsDocument, variables),
      options
    );

useQnaAnswerReviewTagsQuery.getKey = (variables?: Types.QnaAnswerReviewTagsQueryVariables) => variables === undefined ? ['QnaAnswerReviewTags'] : ['QnaAnswerReviewTags', variables];
;

useQnaAnswerReviewTagsQuery.fetcher = (variables?: Types.QnaAnswerReviewTagsQueryVariables, options?: RequestInit['headers']) => gqlFetcher<Types.QnaAnswerReviewTagsQuery, Types.QnaAnswerReviewTagsQueryVariables>(QnaAnswerReviewTagsDocument, variables, options);
export const QnaAnswerReviewsDocument = `
    query QnaAnswerReviews($answerPid: ID!) {
  qnaAnswerReviews(answerPid: $answerPid) {
    id
    answerPid
    rating
    content
    reviewTags {
      id
      text
    }
  }
}
    `;
export const useQnaAnswerReviewsQuery = <
      TData = Types.QnaAnswerReviewsQuery,
      TError = Types.GraphqlError
    >(
      variables: Types.QnaAnswerReviewsQueryVariables,
      options?: UseQueryOptions<Types.QnaAnswerReviewsQuery, TError, TData>
    ) =>
    useQuery<Types.QnaAnswerReviewsQuery, TError, TData>(
      ['QnaAnswerReviews', variables],
      gqlFetcher<Types.QnaAnswerReviewsQuery, Types.QnaAnswerReviewsQueryVariables>(QnaAnswerReviewsDocument, variables),
      options
    );

useQnaAnswerReviewsQuery.getKey = (variables: Types.QnaAnswerReviewsQueryVariables) => ['QnaAnswerReviews', variables];
;

useQnaAnswerReviewsQuery.fetcher = (variables: Types.QnaAnswerReviewsQueryVariables, options?: RequestInit['headers']) => gqlFetcher<Types.QnaAnswerReviewsQuery, Types.QnaAnswerReviewsQueryVariables>(QnaAnswerReviewsDocument, variables, options);